<template>
  <div class="container index text-center">
    <div
      class="d-flex flex-column ma-5 text-center"
      v-if="loading || dynamicQrCode"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        class="mx-auto my-4"
      />
      <div class="text-subtitle-2">{{ executionStatus }}</div>
    </div>

    <div v-if="!loading && !dynamicQrCode">
      <v-alert class="ma-5" outlined type="error">Invalid QR Code </v-alert>
    </div>

    <v-dialog max-width="350" v-model="requireAccessCode">
      <v-card>
        <v-card-content>
          <v-card-text>
            <div class="text-subtitle2">
              You are trying to access a protected resource, please enter your
              access code to proceed.
            </div>
            <v-text-field class="mt-5" v-model="accessCode" label="Access Code" />
          </v-card-text>
        </v-card-content>
        <v-card-actions class="justify-center">
          <v-btn class="ma-5" @click="validateAccessCode()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getDynamicQrCode } from "@/services/dynamicQrCode";
import { mapActions } from "vuex";

export default {
  data: () => {
    return {
      loading: true,
      jsonData: null,
      dynamicQrCode: null,
      storedData: null,
      executionStatus: "Processing QR Code...",
      requireAccessCode: false,
      accessCode: null,
    };
  },
  mounted() {
    const uuid = this.$route.params.uuid;
    const url = this.$route.params.url;
    const data = this.$route.params.data;
    if (uuid) {
      this.loadDynamicQrCode(uuid);
    } 
    else if(url){
      this.processRedirect(url);
    }
    else if(data){
      this.processStorage(data);
    }
    else {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("messages", ["addErrorMessage"]),
    loadDynamicQrCode(uuid) {
      getDynamicQrCode(uuid).then((response) => {
        this.dynamicQrCode = response.data;
        if (this.dynamicQrCode.requireAccessCode) {
          //prompt for access code
          this.requireAccessCode = true;
        } else {
          this.processDynamicQrCode(this.dynamicQrCode);
        }

        this.loading = false;
      });
    },
    processDynamicQrCode(dynamicQrCode) {
      this.jsonData = JSON.parse(dynamicQrCode.jsonData);
      const type = dynamicQrCode.type;
      switch (type) {
        case "EXECUTION":
          this.processExecution(this.jsonData);
          break;
        case "LINK":
          this.processRedirect(this.jsonData);
          break;
        case "DATA":
          this.processStorage(this.jsonData);
          break;
      }
    },
    validateAccessCode(){
      const data = {
        uuid: this.dynamicQrCode.uuid,
        accessCode: this.accessCode
      }
      this.$axios.post('/dynamicQrCode/validate-access-code', data)
        .then(() => {
          this.processDynamicQrCode(this.dynamicQrCode);
          this.loading = false;
        })
        .catch( () => {
          this.addErrorMessage('Invalid access code');
          this.loading = false;
        })
    },
    async processExecution(steps) {
      if (steps && steps.length > 0) {
        let previousResponse = null;
        let index = 0;
        for (const s of steps) {
          const requestMethod = s.requestMethod;
          this.executionStatus = `Processing step ${
            index + 1
          }, ${requestMethod}:${
            s.endpoint || s.path || s.route || s.targetUrl
          }`;
          switch (requestMethod) {
            case "POST":
              previousResponse = await this.postStep(s);
              console.log(previousResponse);
              break;
            case "REDIRECT":
              if (s.route) {
                this.$router.replace(s.route);
              }
              if (s.path) {
                this.$router.replace({ path: s.path });
              } else if (s.targetUrl) {
                window.location.replace(s.targetUrl);
              }
              break;
          }

          index++;
        }
      }
    },
    processRedirect(link) {
      this.executionStatus = `Redirecting to: ${link}`;
      window.location.replace(link);
      this.loading = false;
    },
    processStorage(data) {
      this.executionStatus = `Displying stored data: ${data}`;
      this.storedData = data;
      this.$router.replace({ name: 'QrData', params: {data} });
      
      this.loading = false;
    },
    async postStep(step) {
      if (step.endpoint) {
        if(this.dynamicQrCode && step.params){
          step.params.dynamicQrCodeId = this.dynamicQrCode.id;
        }

        return await this.$axios
          .post(step.endpoint, step.params)
          .then((response) => {
            return response.data;
          });
      } else {
        return null;
      }
    },
  },
};
</script>

<style></style>
